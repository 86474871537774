@import url("fonts.css");
* {
  margin: 0px;
  padding: 0px;
}
button,
button:focus,
button:active,
button:hover {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0px;
  padding: 0px;
}
.mr-b10 {
  margin-bottom: 10px;
}
.mr-b20 {
  margin-bottom: 20px;
}
.mr-b30 {
  margin-bottom: 30px;
}
.mr-b50{
  margin-bottom: 50px;
}
.mr-t10 {
  margin-top: 10px;
}
.mr-t20 {
  margin-top: 20px;
}
.mr-t30 {
  margin-top: 30px;
}
.mr-t50 {
  margin-top: 50px;
}
.screen-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.black-title {
  height: 90px;
  background-color: #212121;
  box-sizing: border-box;
  padding: 43px 20px 10px;
  display: flex;
  align-items: center;
}
.blue-title {
  background-color: #2dc3e8;
}
.black-title h1 {
  margin-bottom: 0px;
  font-size: 26px;
  line-height: 24px;
  color: #ffffff;
  font-family: Segoe-UI-Bold;
}
.black-title h2 {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  font-family: Segoe-UI-Bold;
}
.blue-btn {
  background: #2dc3e8;
  box-shadow: 0px 12px 35px rgba(214, 243, 250, 0.9);
  border-radius: 5px;
  border: 0px;
  width: 100%;
  min-height: 48px;
  font-size: 20px;
  color: #FFFFFF;
  font-family: Segoe-UI-Bold;
}
.trans-blue-btn{
  background: transparent;
  box-shadow: 0px 12px 35px rgba(214, 243, 250, 0.9);
  border-radius: 5px;
  border: 1px solid #2dc3e8;
  width: 100%;
  min-height: 48px;
  font-size: 20px;
  color: #2dc3e8;
  font-family: Segoe-UI-Bold;
}
.back-btn {
  background-color: transparent;
  border: 0px;
  padding: 0px;
  margin-right: 20px;
  line-height: 0;
}
.app-body {
  padding: 20px 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.flex1 {
  flex: 1;
}
.page-title {
  font-size: 24px;
  line-height: 32px;
  color: #212121;
  font-family: Segoe-UI-Bold;
}
.list-block ul li {
  list-style: none;
  font-size: 14px;
  line-height: 16px;
  color: #212121;
  font-family: Roboto-Regular;
  margin-bottom: 20px;
}
.list-block ul li:last-child {
  margin-bottom: 0px;
}
.doc-example-block h3 {
  font-size: 20px;
  line-height: 27px;
  color: #212121;
  font-family: Segoe-UI-Bold;
}
.list-block h5 {
  font-size: 20px;
  color: #212121;
  font-family: Segoe-UI-Bold;
}
.ex-doc {
  background: url("../images/frame-rt.png") right top no-repeat, url("../images/frame-rb.png") right bottom no-repeat,
    url("../images/frame-lb.png") left bottom no-repeat, url("../images/frame-lt.png") left top no-repeat;
  padding: 20px;
  position: relative;
}
.ex-doc::after {
  content: "";
  position: absolute;
  background-color: #c4c4c4;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.ex-doc img {
  max-width: 100%;
  height: auto;
}
.page-sub-title {
  font-size: 13px;
  color: #707070;
  font-family: Segoe-UI;
}
.road-map-wrap ul li {
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.road-map-wrap ul li:nth-child(even) {
  flex-direction: row-reverse;
  text-align: right;
}

.ver-wrap {
  position: relative;
  background: url("../images/screen-bg.svg") no-repeat top center;
  background-size: cover;
}
.ver-wrap::before {
  content: "";
  width: 75px;
  height: 75px;
  position: absolute;
  top: 25px;
  right: 20px;
  background: rgba(255, 155, 33, 0.1);
  transform: matrix(1, 0, 0, -1, 0, 0);
  border-radius: 50%;
}
.map-icon {
  flex: 150px;
  width: 100%;
  max-width: 150px;
}
.map-info {
  margin-left: 15px;
}
.map-info h5 {
  font-size: 14px;
  color: #292929;
  font-family: seguisb;
}
.road-map-wrap ul li:nth-child(even) .map-info {
  margin-left: 0px;
  margin-right: 15px;
}
.divaider {
  border-top: 1px dashed #000000;
  height: 1px;
  width: 46%;
  position: absolute;
  bottom: -12px;
  z-index: -1;
  left: 50%;
}
.road-map-wrap ul li:nth-child(even) .divaider {
  transform: rotate(-35deg) translateX(-50%);
  -webkit-transform-origin-x: left;
}
.road-map-wrap ul li:nth-child(odd) .divaider {
  transform: rotate(35deg) translateX(-50%);
  -webkit-transform-origin-x: left;
}
.road-map-wrap ul li:last-child .divaider {
  display: none;
}
.upload-list ul li {
  list-style: none;
  display: flex;
  align-items: center;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
}
.upload-list ul li .l-icon-inn {
  background: #d9edf7;
  width: 51px;
  height: 51px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.u-list-info h5 {
  font-size: 16px;
  color: #0a0a0a;
  font-family: Segoe-UI;
  margin-left: 20px;
}
.u-link-icon button {
  background: transparent;
  border: 0px;
  padding: 0px;
  position: absolute;
  top: 10px;
  right: 20px;
}
.para-text{
  font-size: 16px;
  color: #5F5F5F;
  font-family: Segoe-UI;
}