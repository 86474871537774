.wrapper {
    position: relative;
    width: 20px;
    height: 400px;
    margin: 10px;
}

.line {
    position: absolute;
    left: 49%;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #ccc;
    z-index: 1;
}

.wordwrapper {
    text-align: center;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -12px;
    z-index: 2;
}

.word {
    color: #ccc;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 3px;
    font: bold 12px arial,sans-serif;
    background: #f5fdff;
}
